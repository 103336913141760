import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["submit", "field"]

  connect() {
    this.toggleSubmitButton()
  }

  validate() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    const allValid = this.fieldTargets.every(field => field.value.trim() !== "")
    this.submitTarget.disabled = !allValid
  }
}
